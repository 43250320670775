.testimonialCard {
  max-width: 500px;
  width: 100%;
  display: grid;

  overflow: hidden;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  color: black;
  background-color: white;
  border-radius: 30px;

  @media (max-width: 376px) {
    grid-template-columns: auto;
    justify-items: center;
    height: fit-content;
  }
  & a {
    text-decoration: none;
    color: black;
  }
}
.testimonialInfo {
  display: flex;
  padding: 20px 30px 50px 30px;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  @media (max-width: 376px) {
    grid-template-columns: auto;
    align-items: center;
    justify-content: center;
  }
}
.testimonialContent {
  font-size: 14px;
  text-align: center;
  @media (max-width: 376px) {
    text-align: center;
  }
  @media (max-height: 790px) and (max-width: 1530px) {
    font-size: 11px;
  }
}
.testiomnialHeader {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1px;
}
.testimonialLabel {
  opacity: 0.8;
  font-size: 15px;

  text-align: center;
}
.testimonialTitle {
  font-size: 20px;
}
.testimonialImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonialanchor {
  opacity: 0.8;
  font-size: 15px;
  transition: all 0.5s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  @media (max-width: 376px) {
    text-align: center;
  }
}
