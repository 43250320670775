@import "./mixins";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
html {
  background-color: #40940a;
  font-family: Montserrat, Sans-serif;
  color: white;
  scroll-behavior: smooth;
  //background: linear-gradient(to top, #434343 0%, black 100%);
}
.logo_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.app {
  width: 500%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  color: white;
}
.roadmap_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 70px;
  background-color: white;
  gap: 20px;
  border-radius: 30px;
  width: 400px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }
  span {
    color: #000;
    text-align: center;
  }
  @include respond(phone) {
    width: 300px;
  }
}
.roadmap_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  svg {
    font-size: 60px;
    color: white;
  }
  @include respond(tab-land) {
    flex-direction: column;
  }
}
.roadmap_container {
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
}
.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  a {
    font-size: 20px;
    text-decoration: none;
    color: white;
  }
}
.navbarm {
  padding-top: 35px;
}
.bars {
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  justify-content: flex-end;
}
.bar {
  width: 40px;
  background-color: white;
  margin: 3px 0;
  height: 3px;
}
.nft_texts {
  max-width: 1200px;
  width: 100%;
  text-align: center;
  h3 {
    margin: 10px 0;
    text-align: center;
  }
}

.merchanise_images {
  display: flex;
  gap: 20px;
  img {
    width: 400px;
    height: 100%;
    border-radius: 40px;
    @include respond(tab-land) {
      width: 300px;
      height: 100%;
    }
  }
  @include respond(tab-land) {
    flex-direction: column;
  }
}

.nft_images {
  display: flex;
  gap: 20px;
  img {
    width: 400px;
    height: 400px;
    border-radius: 40px;
    @include respond(tab-land) {
      width: 300px;
      height: 300px;
    }
  }
  @include respond(tab-land) {
    flex-direction: column;
  }
}

.game_images {
  display: flex;
  gap: 20px;
  img {
    width: 400px;
    height: 400px;
    border-radius: 40px;
    @include respond(tab-land) {
      width: 300px;
      height: 300px;
    }
  }
  @include respond(tab-land) {
    flex-direction: column;
  }
}
.game_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 400px;
  height: 100%;
  padding: 0 40px;
  p {
    text-align: center;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
  }
}

.blogcontainer {
  margin: 150px auto 0;
  max-width: 1200px;
  padding: 0 30px;
  text-align: center;
  width: 100%;
  position: relative;
  & a {
    color: #2ea3f2;
    opacity: 0.8;
    overflow: hidden;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  & img {
    height: auto;
    width: 100%;
    z-index: 9999;
  }
}

h1 {
  font: italic small-caps 300 4.5rem Impact;
  text-transform: uppercase;
  font-size: 80px;
  padding: 0 20px;
  @include respond(tab-land) {
    font-size: 50px;
    text-align: center;
  }
}

h2 {
  font-size: 60px;
  text-transform: uppercase;
}
h3 {
  text-align: left;
  font-size: 40px;
}
h4 {
  text-align: left;
  font-size: 30px;
  opacity: 0.4;
}
i,
p,
q {
  word-wrap: break-word;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 20px;
  font-weight: 450;
  line-height: 2em;
  text-align: left;
}
.parcels {
  z-index: -1;
}

input {
  border: none;
  align-items: stretch;
  outline: none;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid rgb(31, 29, 29, 0.6);
}
textarea {
  resize: none;
  border: none;
  outline: none;
  align-items: stretch;
  padding: 10px;
  border-radius: 2px;
  width: 100% !important;
  border: 1px solid rgb(31, 29, 29, 0.6);
}
input::placeholder {
  opacity: 0.7;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}
textarea::placeholder {
  opacity: 0.7;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}
input:focus-visible,
textarea:focus-visible {
  outline: none;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0.5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #078600;
  border-radius: 10px;
}
.footer_socials {
  display: flex;
  gap: 20px;
  font-size: 50px;
  align-items: center;
  justify-content: flex-start;
  & svg {
    color: white;
    overflow: hidden;
    opacity: 0.8;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}
.footer_socials_2 {
  justify-content: center;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0ab501;
}

.container_notFound_items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 30px;
  align-items: center;
  margin: 0;
  position: absolute;
  top: 50%;
  color: white;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seperator {
  height: 50px;
}

li {
  line-height: 4em;
  text-align: left;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 20px;
}
svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
}
.hero_container {
  // background-image: linear-gradient(to bottom, #16410b, #367d25 50%, #4caf50);
  background-color: #2a7716;
  margin: 0px auto;
  padding: 40px 80px 40px 80px;
  @include respond(phone) {
    padding: 40px;
  }
}
.cls-1 {
  fill: #16410b;
}
.hero_container_sub {
  margin-top: 60px;
  gap: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond(tab-land) {
    flex-direction: column;
    gap: 50px;
    margin-top: 0px;
    justify-content: flex-start;
  }
}
.hero_phrase {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.hero_phrase_webfalling {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero_explonis_text {
  font-size: 110px;

  opacity: 0.4;
  transform: scale(-1);
  text-transform: uppercase;
  writing-mode: vertical-lr;

  @media (max-width: 1557px) {
    font-size: 100px;
  }
  @media (max-width: 1463px) {
    font-size: 90px;
  }
  @include respond(tab-land) {
    font-size: 50px;
    text-align: center;
    opacity: 0.4;
    transform: none;
    writing-mode: horizontal-tb;
    text-transform: uppercase;
  }
  @media (max-width: 300px) {
    font-size: 40px;
  }
}
.tokenomics_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  h1 {
    margin-bottom: 30px;
  }
  @include respond(tab-land) {
    gap: 50px;
  }
}
.tokenomics_card {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  justify-content: flex-start;
  @include respond(tab-land) {
    flex-direction: column;
    text-align: center;
    gap: 4px;
    padding: 0 50px;
  }
}
.story_container {
  display: flex;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  justify-content: center;
}
.story_sub_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  align-items: center;
  h3 {
    max-width: 700px;
    width: 100%;
    @include respond(tab-land) {
      padding: 0 40px;
      text-align: center;
    }
    @include respond(phone) {
      padding: 0 50px;
      font-size: 30px;
    }
  }
  img {
    width: 500px;
    height: 500px;
    border-radius: 40px;
    @include respond(phone) {
      width: 300px;
      height: 300px;
    }
  }
}
button {
  color: #1f1d1d !important;
  border-width: 0px !important;
  border-radius: 0px;
  letter-spacing: 2px;
  font-size: 14px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  background-color: #fff;
  padding: 30px 50px;
  transition: all 0.2s ease-in-out;

  @media (max-width: 463px) {
    font-size: 12px;
    padding: 20px 40px;
  }

  &:hover {
    cursor: pointer;
    transform: scaleX(105%);
  }
  & a {
    color: white !important;
  }
}

.home_about {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
  justify-content: center;
  h3 {
    text-align: center;
  }
}
.hero_texts {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 800px;
  width: 100%;
  h1 {
    @include respond(tab-land) {
      font-size: 44px;
    }
  }
  h2 {
    opacity: 0.8;
    @include respond(tab-land) {
      font-size: 36px;
    }
  }
  a {
    text-decoration: none;
  }
  h3 {
    font-style: italic;
    font-size: 26px;
  }
}
.hero_main_image {
  width: 600px;
  height: 600px;
  overflow: hidden;
  @include respond(tab-land) {
    width: 300px;
    height: 300px;
  }
}
.hero_webfalling {
  display: flex;

  gap: 10px;
  align-items: center;

  @include respond(phone) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
section {
  position: relative;
  z-index: 9999;
}

.projects_container {
  display: flex;
  margin: 60px 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  gap: 50px;
  padding: 0px 50px;
}

.projects_subcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 50px;
  min-height: 0;
  min-width: 0;
  max-width: 1000px;
  width: 100%;
  height: 100%;

  overflow: hidden;
  padding: 30px 0;
  justify-content: center;
  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone) {
    display: flex;
    flex-direction: column;
    gap: 60px;
    min-height: 0;
    min-width: 0;

    overflow: hidden;
    padding: 30px 0;
  }
}

.footer_container {
  transform: translateY(-10px);
  background-color: #2a7716;
  gap: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px 50px 30px;
  margin: 0px auto;
  @include respond(tab-land) {
    padding: 50px 30px 50px 30px;
  }
}
.footer_testimonials {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  justify-content: center;
}

.footer_contacts {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  height: 100%;
}

.footer_forminputs,
.footer_heartsandformtext {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer_formText {
  align-self: center;
}
.footer_hearts {
  align-self: center;
}

.footer_copyright {
  font-size: 20px;
  overflow: hidden;
  text-align: center;
}

.copy-to-clipboard {
  display: flex;
  max-width: 600px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 30px;
  background-color: #f1f1f1;
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.2));
  border-radius: 4px;
}

.copy-to-clipboard span {
  font-size: 16px;
  color: black;
}

.copy-to-clipboard button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;

  margin-left: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.copy-to-clipboard button:hover {
  background-color: #ccc;
}

.copy-to-clipboard button:active {
  background-color: #aaa;
}

.copy-to-clipboard button svg {
  width: 24px;
  height: 24px;
  fill: #000;
  margin-right: 8px;
}

// .sound-switcher-mob {
//     border: rgba(97, 252, 86, 0.5) 5px solid;
//     border-radius: 90px;
//     animation-name: soundPulse-mod;
//     animation-duration: .5s;
//     animation-iteration-count: infinite
// }
// @keyframes soundPulse-mod {
//     0% {
//         border: rgba(97, 252, 86, 0.5) 5px solid
//     }

//     50% {
//         border: rgba(97, 252, 86, 0.5) 10px solid
//     }

//     to {
//         border: rgba(97, 252, 86, 0.5) 5px solid
//     }
// }
